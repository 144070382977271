import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  AppContent,
  AppSidebar,
  AppFooter,
  AppHeader,
} from "../components/internal/index";
// import "../config/scss/style.scss";

const DefaultLayout = () => {
  const navigate = useNavigate();

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  };

  useEffect(() => {
    const checkUserLogged = () => {
      if (
        sessionStorage.getItem("accessToken") !== null &&
        sessionStorage.getItem("id") !== null
      ) {
        const checkToken = sessionStorage.getItem("accessToken") ?? "";

        console.log("banana:", isTokenExpired(checkToken));

        if (isTokenExpired(checkToken)) {
          navigate("/home");
        }
      } else {
        navigate("/home");
      }

      import("../config/scss/style.scss");
    };
    checkUserLogged();
  }, [navigate]);

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader />
        <div className="body flex-grow-1">
          <AppContent />
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default DefaultLayout;
